<script>
import useJwt from '@/auth/useJwt'
// eslint-disable-next-line

export default {
  name: 'AffiliateMarketingIndex',
  data() {
    return {
      isLoading: false,
      customers: null,
    }
  },
  created() {
    this.getCustomers()
  },
  methods: {
    deleteCustomer(id) {
      const result = confirm('Do you really want to delete this customer ?')
      console.log(result)
      if (result) {
        useJwt.delete(`/customers/${id}`)
          .then(() => {
            this.getCustomers()
          }).catch(err => {
            console.error(err)
          }).finally()
      }
    },
    getCustomers(page = 1) {
      this.isLoading = true
      useJwt.get(`/customers?page=${page}`)
        .then(({ data }) => {
          this.customers = data
        }).catch(err => {
          console.error(err)
        }).finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-card-body>
            <h3>
              {{ $t('Affiliate Marketing') }}
            </h3>
            <div class="mt-1">
              {{ $t('Affiliate Marketing Description') }}
            </div>
            <b-button to="/affiliate-marketing/settings" size="sm" variant="primary" class="mt-2">
              <feather-icon icon="SettingsIcon" />
              {{ $t('Settings') }}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-overlay
      :show="isLoading"
      rounded
      opacity="0.6"
      spinner-variant="primary"
      spinner-mediam
    >
      <b-row>
        <b-col class="text-right">
          <b-button
            to="/affiliate-marketing/create"
            size="sm"
            variant="success"
            class="mb-1"
          >
            <feather-icon icon="PlusSquareIcon" />
            {{ $t('Add New Marketer') }}
          </b-button>
        </b-col>
      </b-row>
      <b-row
        v-if="!isLoading"
        class="match-height"
      >
        <template v-if="customers !== null">
          <template v-for="(customer, index) in customers.data">
            <transition
              :key="index"
              :name="$store.state.appConfig.layout.routerTransition"
              mode="out-in"
            >
              <b-col md="12">
                <b-card no-body>
                  <b-row>
                    <b-col
                      md="9"
                      cols="8"
                    >
                      <b-card-body>
                        <b-card-title>
                          <div>
                            <span>#{{ customer.id }}</span> {{ customer.shopper.name }}
                          </div>
                          <div>
                            <span class="text-secondary small">سجل في {{ customer.created_at }}</span>
                          </div>
                          <div class="mt-1">
                            {{ $t("Commission") }}: {{ customer.commission }} <span>%</span>
                          </div>
                          <div class="mt-1">
                            {{ $t("Balance") }}: <span dir="ltr">{{ customer.balance }}</span>  <span>{{ $t("SAR") }}</span>
                          </div>
                        </b-card-title>
                        <b-row class="m-0">
                          <b-badge
                            class="d-block"
                            :variant="customer.is_active ? 'light-success' : 'light-danger'"
                          >
                            {{ customer.is_active ? $t('Active') : $t('Not Active') }}
                          </b-badge>
                          <b-badge class="d-block ml-1">
                            {{ customer.shopper.mobile }}
                          </b-badge>
                          <b-badge class="d-block ml-1">
                            {{ customer.shopper.email }}
                          </b-badge>
                        </b-row>
                      </b-card-body>
                    </b-col>
                    <b-col
                      md="3"
                      cols="4"
                      align-self="center"
                      class="text-center"
                    >
                      <b-button
                        variant="outline-primary"
                        class="btn-icon mr-1"
                        :to="`/affiliate-marketing/${customer.id}/edit`"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-button>
                      <!-- b-button
                        variant="outline-danger"
                        class="btn-icon"
                        @click="deleteCustomer(customer.id)"
                      >
                        <feather-icon icon="XCircleIcon" />
                      </b-button-->
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </transition>
          </template>
        </template>
        <template v-else>
          <b-col>
            <b-card class="text-center">
              <span>{{ $t('content not found') }}.</span>
            </b-card>
          </b-col>
        </template>
      </b-row>
      <pagination
        v-if="customers"
        :limit="3"
        :data="customers"
        @pagination-change-page="getCustomers"
      />
    </b-overlay>
  </div>
</template>

<style scoped lang="scss">

</style>
